import React from 'react'
import { Container } from 'react-bootstrap'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'

const Dostavka = () => {
  const data = [
    {
      quest: 'Сколько стоит доставка?',
      answer: 'Стоимость доставки зависит от размера бани и расстояния до Вас.'
    },
    {
      quest: 'Какие сроки изготовления?',
      answer:
        'Срок изготовления бани от 4-х до 8 недель, зависит от сложности проекта и загруженности завода.'
    },
    {
      quest: 'Каким транспортом осуществляется доставка и установка бани?',
      answer:
        '<p>Доставка осуществляется еврофурой, тралом или манипулятором, это зависит от территориального расположения нашего заказчика.</p><p>Для установки бани по месту заказчик исходя из общей обстановки на своем участке, размеров и массы бани/дома, заказывает кран либо манипулятор.</p>'
    }
  ]
  return (
    <Layout title="Доставка и оплата" description="Доставка и оплата" brcr={['Доставка и оплата']}>
      <section className="faq_page">
        <Container style={{ position: 'relative' }}>
          <h1>Доставка и оплата</h1>
          <div className={`card`}>
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <input type="checkbox" defaultChecked className={`d-none`} id={`item${index}`} />

                  <label className={`quest`} htmlFor={`item${index}`}>
                    {item.quest}
                  </label>
                  <p
                    className={`answer alt`}
                    dangerouslySetInnerHTML={{ __html: item.answer && item.answer }}
                  />
                </div>
              )
            })}
            <hr />
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Dostavka
